<template>
    <section id="mono-sculpting-disclaimer"
             class="disclaimer py-5">
        <div class="container">
            <h1 class="position-relative text-center font-weight-bold mb-5"
                data-aos="fade"
                data-aos-duration="1000">
                免責聲明
            </h1>
            <h5 class="mb-3 text-center"
                data-aos="fade-up"
                data-aos-duration="1000">
                * 數據來源於 BTL HONG KONG LIMITED 中文小冊子
            </h5>
            <ul data-aos="fade-up"
                data-aos-duration="1000">
                <li class="mb-2">
                    Mcdaniel D, Weiss R, Weiss M, Mazur C, Griffen C. Two-treatment protocol for skin laxity using 90-Watt dynamic monopolar radiofrequency device with real-time impedance intelligence monitoring. Journal of Cosmetic Dermatology. 2014; 13(9): 1112-7.
                </li>
                <li class="mb-2">
                    Chilukuri S, Denjean D, Fouque L. Treating multiple body parts for skin laxity and fat deposits using a novel focused radiofrequency device with an ultrasound component: Safety and efficacy study. Journal of Cosmetic Dermatology. 2017; 16(4): 476-479.
                </li>
                <li class="mb-2">
                    Mcdaniel D, Fritz K, Machovcova A, Bernardy J. A focused monopolar radiofrequency causes apoptosis: a porcine model. Journal of Drugs in Dermatology. 2014; 13(11): 1336-40.
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    export default {
        name: "MonoSculptingDisclaimer",
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .disclaimer {
        background-color: $background-color;
        color: rgba(255, 255, 255, 1);
    }
</style>
